<template>
  <q-layout view="lHr lpR lFr">
    <q-page-container>
      <q-page
        :style="{ backgroundImage: `url(${bg})` }"
        class="img-overlay auth"
      >
        <img
          src="@/assets/rp.svg"
          width="128px"
          class="auth-logo"
          :class="{ active: isLogoActive }"
        />
        <div class="absolute-full text-subtitle2 flex flex-center">
          <transition
            enter-active-class="animated fadeInLeft"
            leave-active-class="animated fadeOut"
          >
            <RouterView />
          </transition>
        </div>
      </q-page>
    </q-page-container>
  </q-layout>
</template>

<script setup>
import { useQuasar } from "quasar";
import { ref } from "vue";

const rand = Math.floor(Math.random() * 5) + 1;
const bg = require(`@/assets/bgs/start_${rand}.jpg`);
const $q = useQuasar();
const isLogoActive = ref(false);
$q.dark.set(false);

setTimeout(() => {
  isLogoActive.value = true;
}, 500);
</script>
