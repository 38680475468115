<template>
  <q-header elevated>
    <q-toolbar>
      <q-space></q-space>
      <Clock></Clock>
      <q-separator vertical inset class="q-mx-sm" />
      <q-btn dense flat no-caps color="amber" icon="notifications"> </q-btn>
      <q-btn
        dense
        flat
        no-caps
        color="positive"
        @click="$q.fullscreen.toggle()"
        :icon="$q.fullscreen.isActive ? 'fullscreen_exit' : 'fullscreen'"
      />
      <q-separator vertical inset class="q-mx-sm" />
      <q-btn dense flat no-caps>
        {{ store.user.name }}
        <q-menu>
          <q-list bordered padding dense>
            <q-item clickable @click="logout" v-close-popup>
              <q-item-section avatar>
                <q-icon name="power_settings_new"></q-icon>
              </q-item-section>
              <q-item-section>Выход</q-item-section>
            </q-item>
          </q-list>
        </q-menu>
      </q-btn>
    </q-toolbar>
  </q-header>
</template>
<script setup>
import Clock from "@/components/layout/clock.vue";
import { useStore } from "@/store";
import { useQuasar } from "quasar";
import { useRouter } from "vue-router";

const store = useStore();
const $q = useQuasar();
const router = useRouter();

const logout = async () => {
  await store.logout();
  router.push({ name: "login" });
};
</script>
