<template>
  <div
    class="flex column q-mt-lg q-col-gutter-y-md justify-center items-center"
  >
    <div class="col-md-6 text-center">
      <div class="text-h5">
        Добро пожаловать в панель администратораRostPoint.
      </div>
    </div>
    <div class="col-md-6"></div>
  </div>
</template>

<script setup></script>
