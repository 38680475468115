"use strict";

import axios from "axios";
import Notify from 'quasar/src/plugins/Notify.js';;

axios.defaults.baseURL = process.env.VUE_APP_API_URL;
axios.interceptors.request.use(
  (config) => {
    config.headers["X-Requested-With"] = "XMLHttpRequest";
    const token = localStorage.token;
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }
    if (window.Echo) {
      config.headers["X-Socket-ID"] = window.Echo.socketId();
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);
axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    let message;
    if (error.response.status == 400) {
      message = error.response.data;
    } else if (error.response.status == 401) {
      message = "Ошибка авторизации, повторите вход";
    } else if (error.response.status == 404) {
      message = "Запись, которую вы ищите не существует";
    } else if (error.response.status == 429) {
      message = "Слишком много действий, попробуйте позже";
    } else if (error.response.status == 403) {
      message = `У вас недостаточно прав для этого действия`;
    } else if (error.response.status == 500) {
      message = "Что-то пошло не так";
    }

    if (message) {
      Notify.create({
        type: "negative",
        message,
      });
    }
    return Promise.reject(error);
  },
);
export default axios;
