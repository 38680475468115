<template>
  <q-layout view="lHr Lpr lfr">
    <Navbar />
    <q-drawer :model-value="true" elevated :width="220" class="menu">
      <q-scroll-area
        :horizontal-thumb-style="{ opacity: 0 }"
        class="full-height"
      >
        <q-list dense>
          <div>
            <q-item clickable v-ripple :active="$route.active" exact to="/">
              <q-item-section>Дашборд</q-item-section>
            </q-item>
          </div>
          <div v-for="item in menu" :key="item.id">
            <q-item
              clickable
              v-ripple
              :active="$route.active"
              :to="{ name: item.id }"
            >
              <q-item-section>{{ item.name }}</q-item-section>
            </q-item>
          </div>
        </q-list>
      </q-scroll-area>
    </q-drawer>
    <q-page-container>
      <transition
        enter-active-class="animated fadeInLeft"
        leave-active-class="animated fadeOut"
      >
        <RouterView class="q-pa-sm" />
      </transition>
    </q-page-container>
  </q-layout>
</template>

<script setup>
import Navbar from "@/components/layout/navbar.vue";
import { onMounted } from "vue";
import { useQuasar } from "quasar";

const $q = useQuasar();
const menu = [{ id: "clients", name: "Клиенты" }];

onMounted(async () => {
  $q.dark.set(true);
});
</script>
