import { defineStore } from "pinia";
import axios from "@/services/axios";

export const useStore = defineStore("store", {
  state: () => ({
    token: window.localStorage.getItem("token"),
    user: null,
  }),
  getters: {
    auth() {
      return Boolean(this.user);
    },
  },
  actions: {
    async logout() {
      try {
        if (this.auth) {
          await axios.post("logout");
        }
      } catch (e) {
        //
      } finally {
        localStorage.removeItem("token");
        this.token = null;
        this.user = null;
        this.employer = null;
      }
    },

    async loadData() {
      try {
        const { data: user } = await axios.get("me");
        this.user = user;
      } catch (e) {
        this.logout();
      }
    },
    async toggleNotification(key) {
      try {
        const { data } = await axios.post(`toggleNotification/${key}`);
        this.user.notification_settings[key] = data;
      } catch (e) {
        //
      }
    },
    async toggleUi(key) {
      try {
        const { data } = await axios.post(`toggleUi/${key}`);
        this.user.ui_settings[key] = data;
      } catch (e) {
        //
      }
    },
    setToken(data) {
      this.token = data;
      localStorage.setItem("token", data);
    },
  },
});
