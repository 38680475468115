import script from "./Main.vue?vue&type=script&setup=true&lang=js"
export * from "./Main.vue?vue&type=script&setup=true&lang=js"

const __exports__ = script;

export default __exports__
import QLayout from 'quasar/src/components/layout/QLayout.js';
import QDrawer from 'quasar/src/components/drawer/QDrawer.js';
import QScrollArea from 'quasar/src/components/scroll-area/QScrollArea.js';
import QList from 'quasar/src/components/item/QList.js';
import QItem from 'quasar/src/components/item/QItem.js';
import QItemSection from 'quasar/src/components/item/QItemSection.js';
import QPageContainer from 'quasar/src/components/page/QPageContainer.js';import Ripple from 'quasar/src/directives/Ripple.js';
import qInstall from "../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QLayout,QDrawer,QScrollArea,QList,QItem,QItemSection,QPageContainer});qInstall(script, 'directives', {Ripple});
